import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { addHelpIssue } from "../api/addHelpIssue";
import { enqueueSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ReactMarkdown from "react-markdown";

export default function ChangeLogPage() {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#86a315",
    "&:hover": {
      backgroundColor: "#587d18",
    },
  }));

  const [posts, setPosts] = useState(null);

  const importAllPosts = (r) => r.keys().map(r);

  const markdownFiles = importAllPosts(
    require.context("../changelog", false, /\.md$/)
  )
    .sort()
    .reverse();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    const getPosts = async () => {
      await Promise.all(
        markdownFiles.map((file) => {
          return fetch(file).then((res) => res.text());
        })
      )
        .then((res) => setPosts(res))
        .catch((err) => console.log(err));
    };
    getPosts();
  }, []);

  return (
    <>
      <Container
        id="features"
        sx={{ py: { xs: 8, sm: 16 }, pt: { xs: 14, sm: 20 } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div>
              <Typography component="h2" variant="h4" color="text.primary">
                Historia zmian
              </Typography>
            </div>
          </Grid>
          {!posts && (
            <Grid style={{ marginTop: 20 }}>
              Trwa ładowanie danych...
              <LinearProgress color="success" />
            </Grid>
          )}
          {posts &&
            posts.map((post, idx) => (
              <Grid item xs={12} key={idx}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography>
                      <ReactMarkdown>{post}</ReactMarkdown>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
}
