import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { addHelpIssue } from "../api/addHelpIssue";
import { enqueueSnackbar } from "notistack";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ReactMarkdown from "react-markdown";

export default function GDPRPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Container
        id="features"
        sx={{ py: { xs: 8, sm: 16 }, pt: { xs: 14, sm: 20 } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography component="h2" variant="h4" color="text.primary">
              Obowiązek informacyjny w związku z przetwarzaniem danych osobowych
              – formularz płatności on-line DSCZ – osoby spoza ZHP
            </Typography>
            <ol>
              <li>
                Administratorem danych osobowych jest Związek Harcerstwa
                Polskiego (dalej: „ADMINISTRATOR”), z siedzibą ul. Marii
                Konopnickiej 6, 00-491 Warszawa. Z Administratorem można się
                kontaktować pisemnie, za pomocą poczty tradycyjnej na adres: ul.
                Marii Konopnickiej 6, 00-491 Warszawa lub drogą mailową:
                sekretariat@zhp.pl.
              </li>
              <li>
                Administrator wyznaczył Inspektora Ochrony Danych, z którym
                można się skontaktować pod adresem mailowym: rodo@zhp.pl.
              </li>
              <li>
                Dane osobowe są przetwarzane na podstawie Rozporządzenia
                Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
                2016 r. w sprawie ochrony osób fizycznych w związku z
                przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
                takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                rozporządzenie o ochronie danych).
              </li>
              <li>
                Przetwarzanie danych osobowych w ramach korzystania z formularza
                płatności DSCZ odbywa się w celu:
              </li>
              <ol type="a">
                <li>
                  wykonywania obowiązków prawnych nałożonych na Administratora
                  m.in. przyjęcie płatności i rozliczenie finansowe, wystawienie
                  dokumentów księgowych – art. 6 ust. 1 lit. b, c RODO – dane
                  będą przechowywane przez 5 lat od czasu zakończenia roku, w
                  którym nastąpiło wystawienie dokumentu księgowego;
                </li>
                <li>
                  ustalenia i dochodzenia roszczeń lub obrony przed roszczeniami
                  - art. 6 ust. 1 lit. f RODO – dane będą przechowywane 3 lata
                  od wykonania płatności.
                </li>
              </ol>
              <li>
                Dane osobowe mogą pochodzić od strony trzeciej tj. wpłacającego
                DSCZ.
              </li>
              <li>
                Administrator nie zamierza przekazywać danych do państwa
                trzeciego ani do organizacji międzynarodowej.
              </li>
              <li>
                Administrator będzie przekazywał dane osobowe innym podmiotom,
                tylko na podstawie przepisów prawa, w szczególności do banków
                obsługujących płatność oraz na podstawie umów powierzenia w tym
                do dostawców usług teleinformatycznych, w szczególności
                Microsoft Sp. z o.o., Fakturownia.
              </li>
              <li>Posiada Pan/ Pani prawo do:</li>
              <ol type="a">
                <li>
                  żądania dostępu do danych osobowych, ich sprostowania,
                  usunięcia lub ograniczenia przetwarzania;
                </li>
                <li>
                  wniesienia sprzeciwu wobec przetwarzania, a także o prawie do
                  przenoszenia danych;
                </li>
                <li>
                  wniesienia skargi na działania Administratora do Prezesa
                  Urzędu Ochrony Danych Osobowych.
                </li>
              </ol>
              <li>
                Podanie danych osobowych jest konieczne, aby móc dokonać
                płatności i otrzymać dowody księgowe.
              </li>
              <li>
                Administrator nie przewiduje zautomatyzowanego podejmowania
                decyzji.
              </li>
            </ol>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
