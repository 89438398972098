import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import Layout from "./Layout";
import { Route, BrowserRouter, Routes } from "react-router-dom";

// Components
import Hero from "./components/Hero";
import FormPage from "./pages/FormPage";
import HelpPage from "./pages/HelpPage";
import ChangeLogPage from "./pages/ChangeLogPage";
import GDPRPage from "./pages/GDPRPage";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Hero />} />
          <Route path="formularz" element={<FormPage />} />
          <Route path="pomoc" element={<HelpPage />} />
          <Route path="changelog" element={<ChangeLogPage />} />
          <Route path="gdpr" element={<GDPRPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
