import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ZHPGreenButton from "./Buttons/ZHPGreenButton";

export default function Hero() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Box id="hero">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 15 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap>
          <Typography
            component="h2"
            variant="h4"
            color="text.primary"
            sx={{
              width: { sm: "100%", md: "100%" },
            }}
          >
            Automatyczne płatności DSCZ
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: "center" }}
          >
            Za pomocą tej strony możesz samodzielnie opłacić Dodatkową Składkę
            Członkowską Zadaniową za udział w wydarzeniach organizowanych na
            poziomie centralnym. Po wypełnieniu formularza otrzymasz proformę z
            możliwością płatności online, po której otrzymasz właściwą fakturę.
          </Typography>
          <ZHPGreenButton to="/formularz">
            Przejdź do formularza płatności
          </ZHPGreenButton>
        </Stack>
      </Container>
    </Box>
  );
}
