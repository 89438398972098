import React, { useEffect, useState } from "react";
import {
  Alert,
  Checkbox,
  FormHelperText,
  Grid,
  LinearProgress,
  Link,
  MenuItem,
  Modal,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { getCompanyInfo } from "../api/getCompanyInfo";
import { enqueueSnackbar } from "notistack";
import CheckIcon from "@mui/icons-material/Check";
import { getProducts } from "../api/getProducts";
import isValidNIP from "is-valid-nip";
import { addInvoice } from "../api/addInvoice";

export default function Form() {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#86a315",
    "&:hover": {
      backgroundColor: "#587d18",
    },
    "&:disabled": {
      backgroundColor: "grey",
      color: "white",
    },
  }));

  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const recaptchaRef = React.createRef();

  useEffect(() => {
    getProducts()
      .then((res) => {
        setProducts(res.data);
      })
      .catch((error) => {
        enqueueSnackbar("[Wydarzenia] Błąd pobierania danych: " + error, {
          variant: "error",
        });
      });
  }, []);

  const schema = Yup.object().shape({
    event: Yup.string().required("Wydarzenie jest wymagane!"),
    form_first_name: Yup.string().required("Imię jest wymagane!"),
    form_last_name: Yup.string().required("Nazwisko jest wymagane!"),
    email_address: Yup.string()
      .required("Adres e-mail jest wymagany!")
      .email("Adr es e-mail jest niepoprawny!"),
    company: Yup.boolean().required("Rodzaj nabywcy jest wymagany!"),
    name: Yup.string().test({
      name: "name",
      exclusive: false,
      params: {},
      message: "Nazwa jest wymagana!",
      test: function (value) {
        return this.parent.company ? (value ? true : false) : true;
      },
    }),
    first_name: Yup.string().test({
      name: "name",
      exclusive: false,
      params: {},
      message: "Imię jest wymagane!",
      test: function (value) {
        return this.parent.company ? true : value ? true : false;
      },
    }),
    last_name: Yup.string().test({
      name: "name",
      exclusive: false,
      params: {},
      message: "Nazwisko jest wymagany!",
      test: function (value) {
        return this.parent.company ? true : value ? true : false;
      },
    }),
    tax_no: Yup.string()
      .test({
        name: "name",
        exclusive: false,
        params: {},
        message: "NIP jest wymagany!",
        test: function (value) {
          return this.parent.company ? (value ? true : false) : true;
        },
      })
      .test({
        name: "validNIP",
        exclusive: false,
        params: {},
        message: "NIP jest niepoprawny!",
        test: function (value) {
          return this.parent.company ? isValidNIP(value) : true;
        },
      }),
    street: Yup.string().required("Ulica i numer są wymagane!"),
    post_code: Yup.string()
      .required("Kod pocztowy jest wymagany!")
      .matches(
        "^([_0-9][0-9]|[0-9][_0-9])-([_0-9]{2}[0-9]|[_0-9][0-9][_0-9]|[0-9][_0-9]{2})$",
        "Kod pocztowy jest nieprawidłowy!"
      ),
    city: Yup.string().required("Miasto jest wymagane!"),
    recipient_name: Yup.string().test({
      name: "recipient_name",
      exclusive: false,
      params: {},
      message: "Nazwa odbiorcy jest wymagana!",
      test: function (value) {
        return this.parent.recipient ? (value ? true : false) : true;
      },
    }),
    recipient_street: Yup.string().test({
      name: "recipient_name",
      exclusive: false,
      params: {},
      message: "Ulica i numer odbiorcy są wymagane!",
      test: function (value) {
        return this.parent.recipient ? (value ? true : false) : true;
      },
    }),
    recipient_post_code: Yup.string()
      .matches(
        "^([_0-9][0-9]|[0-9][_0-9])-([_0-9]{2}[0-9]|[_0-9][0-9][_0-9]|[0-9][_0-9]{2})$",
        "Kod pocztowy jest nieprawidłowy!"
      )
      .test({
        name: "recipient_name",
        exclusive: false,
        params: {},
        message: "Kod pocztowy odbiorcy jest wymagany!",
        test: function (value) {
          return this.parent.recipient ? (value ? true : false) : true;
        },
      }),
    recipient_city: Yup.string().test({
      name: "recipient_name",
      exclusive: false,
      params: {},
      message: "Miasto odbiorcy jest wymagane!",
      test: function (value) {
        return this.parent.recipient ? (value ? true : false) : true;
      },
    }),
    quantity: Yup.number()
      .required("Liczba osób jest wymagana!")
      .positive()
      .integer("Liczba osób musi być całkowita!")
      .min(1, "Liczba osób musi być wyższa od 0!")
      .max(50, "Liczba osób musi być mniejsza lub równa 50!"),
    description: Yup.string()
      .min(10, "Dane osób są za krótkie!")
      .required("Dane osób są wymagane!"),
    captcha: Yup.string().required("Weryfikacja reCAPTCHA jest wymagana!"),
  });

  return (
    <Box id="form">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: { xs: 14, sm: 14 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack useFlexGap>
          <Typography
            component="h2"
            variant="h4"
            color="text.primary"
            sx={{
              width: { sm: "100%", md: "100%" },
            }}
          >
            Formularz płatności DSCZ
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mb: { xs: 2, sm: 2 } }}
          >
            Wybierz z listy wydarzenie, za które chcesz opłacić Dodatkową
            Składkę Członkowską Zadaniową. Sprawdź wcześniej informacje od
            organizatorów wydarzenia dotyczące zasad uczestnictwa i dokonywania
            płatności.
          </Typography>
          <Formik
            validationSchema={schema}
            initialValues={{
              event: "",
              form_first_name: "",
              form_last_name: "",
              email_address: "",
              company: "",
              name: "",
              first_name: "",
              last_name: "",
              tax_no: "",
              street: "",
              post_code: "",
              city: "",
              recipient: false,
              recipient_name: "",
              recipient_street: "",
              recipient_post_code: "",
              recipient_city: "",
              quantity: 0,
              description: "",
              member: false,
              rodo: false,
              captcha: null,
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log(JSON.stringify(values, null, 2));
              setLoading(true);

              addInvoice(JSON.stringify(values, null, 2))
                .then((res) => {
                  setLoading(false);
                  setInvoice(res.data);
                })
                .catch((error) => {
                  setLoading(false);
                  enqueueSnackbar(
                    "[Formularz] Błąd wysyłania formularza: " + error,
                    {
                      variant: "error",
                    }
                  );
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              handleReset,
              setFieldValue,
              setTouched,
              /* and other goodies */
            }) => (
              <>
                {loading && (
                  <Grid style={{ marginTop: 20 }}>
                    Trwa ładowanie danych...
                    <LinearProgress color="success" />
                  </Grid>
                )}
                {invoice && (
                  <Grid style={{ marginTop: 20 }}>
                    <Alert
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="success"
                      sx={{ mb: 3, fontSize: "large" }}
                    >
                      <strong>Dziękujemy za wypełnienie formularza!</strong>
                      <br />
                      <br />
                      Wygenerowaliśmy fakturę proforma{" "}
                      <strong>{invoice.number}</strong>, którą możesz szybko
                      opłacić online. Niedługo otrzymasz ją również na wskazany
                      w formularzu adres mailowy.
                      <br />
                      <br />
                      <ColorButton
                        onClick={() => window.open(invoice.view_url)}
                        variant="contained"
                      >
                        PRZEJDŹ DO FAKTURY
                      </ColorButton>
                      <br />
                      <br />
                      Po opłaceniu faktury proforma otrzymasz właściwą fakturę.
                    </Alert>

                    <ColorButton
                      onClick={() => {
                        handleReset();
                        setInvoice(null);
                      }}
                      variant="contained"
                    >
                      POWRÓT
                    </ColorButton>
                  </Grid>
                )}
                {!loading && !invoice && (
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid xs={12}>
                        <Typography></Typography>
                        <h2>Wydarzenie</h2>
                      </Grid>
                      {!products && (
                        <Grid style={{ marginTop: 20 }} item xs={12}>
                          Trwa ładowanie wydarzeń...
                          <LinearProgress color="success" />
                        </Grid>
                      )}
                      {products && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            select
                            name="event"
                            id="event"
                            variant="filled"
                            error={touched.event && Boolean(errors.event)}
                            helperText={
                              touched.event && errors.event
                                ? errors.event
                                : "Po wybraniu wydarzenia zobaczysz kwotę DSCZ."
                            }
                            value={values.event}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={"Wydarzenie"}
                          >
                            <MenuItem value="" disabled>
                              <em>Wybierz z listy</em>
                            </MenuItem>
                            {products &&
                              products.map((product) => (
                                <MenuItem key={product.id} value={product.id}>
                                  {product.name}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      )}

                      {values.event !== "" && (
                        <>
                          <Grid item xs={12} sm={6} style={{ marginTop: 15 }}>
                            <strong>Opis:</strong>
                            <Typography>
                              {
                                products.find((p) => p.id === values.event)
                                  .description
                              }
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ marginTop: 15 }}>
                            <strong>Kwota:</strong>
                            <Typography>
                              {(
                                products.find((p) => p.id === values.event)
                                  .price * 1
                              ).toFixed(2)}{" "}
                              zł.
                            </Typography>
                          </Grid>
                        </>
                      )}

                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <h2>Osoba opłacająca</h2>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        style={{
                          marginTop: 15,
                        }}
                        sx={{
                          paddingRight: { xs: 0, sm: 5 },
                        }}
                      >
                        <TextField
                          fullWidth
                          name="form_first_name"
                          id="form_first_name"
                          variant="filled"
                          error={
                            touched.form_first_name &&
                            Boolean(errors.form_first_name)
                          }
                          helperText={
                            touched.form_first_name && errors.form_first_name
                              ? errors.form_first_name
                              : null
                          }
                          label="Imię"
                          value={values.form_first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          name="form_last_name"
                          id="form_last_name"
                          variant="filled"
                          error={
                            touched.form_last_name &&
                            Boolean(errors.form_last_name)
                          }
                          helperText={
                            touched.form_last_name && errors.form_last_name
                              ? errors.form_last_name
                              : null
                          }
                          label="Nazwisko"
                          value={values.form_last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          name="email_address"
                          id="email_address"
                          variant="filled"
                          error={
                            touched.email_address &&
                            Boolean(errors.email_address)
                          }
                          helperText={
                            touched.email_address && errors.email_address
                              ? errors.email_address
                              : "Na ten adres e-mail otrzymasz fakturę pro-forma i link do płatności."
                          }
                          label="Adres e-mail"
                          value={values.email_address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <h2>Nabywca na fakturze</h2>
                      </Grid>
                      <Grid xs={12}>
                        <FormControl error={errors.company}>
                          <FormLabel component="legends">
                            Rodzaj nabywcy
                          </FormLabel>
                          <RadioGroup
                            id="company"
                            aria-labelledby="company"
                            name="company"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.company}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Firma/Chorągiew"
                              name="company"
                              value="1"
                            />
                            <FormControlLabel
                              value="0"
                              control={<Radio />}
                              label="Osoba prywatna"
                              name="company"
                            />
                          </RadioGroup>
                          <FormHelperText>
                            {touched.company && errors.company
                              ? errors.company
                              : null}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {values.company === "0" && (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{
                              marginTop: 15,
                            }}
                            sx={{
                              paddingRight: { xs: 0, sm: 5 },
                            }}
                          >
                            <TextField
                              fullWidth
                              name="first_name"
                              id="first_name"
                              variant="filled"
                              error={
                                touched.first_name && Boolean(errors.first_name)
                              }
                              helperText={
                                touched.first_name && errors.first_name
                                  ? errors.first_name
                                  : null
                              }
                              label="Imię"
                              value={values.first_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="last_name"
                              id="last_name"
                              variant="filled"
                              error={
                                touched.last_name && Boolean(errors.last_name)
                              }
                              helperText={
                                touched.last_name && errors.last_name
                                  ? errors.last_name
                                  : null
                              }
                              label="Nazwisko"
                              value={values.last_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </>
                      )}
                      {values.company === "1" && (
                        <>
                          {" "}
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="name"
                              id="name"
                              variant="filled"
                              error={touched.name && Boolean(errors.name)}
                              helperText={
                                touched.name && errors.name ? errors.name : null
                              }
                              label="Nazwa"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="tax_no"
                              id="tax_no"
                              variant="filled"
                              error={touched.tax_no && Boolean(errors.tax_no)}
                              helperText={
                                touched.tax_no && errors.tax_no
                                  ? errors.tax_no
                                  : "Wprowadź NIP, aby automatycznie pobrać dane firmy lub chorągwi."
                              }
                              label="NIP"
                              value={values.tax_no}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              marginTop: { xs: 2, sm: 3 },
                              paddingLeft: 2,
                              paddingRight: 2,
                            }}
                          >
                            <center>
                              <ColorButton
                                fullWidth
                                disabled={
                                  (touched.tax_no && errors.tax_no) ||
                                  !values.tax_no
                                }
                                onClick={() => {
                                  getCompanyInfo(values.tax_no)
                                    .then((res) => {
                                      setFieldValue(
                                        "name",
                                        res.data.results.name
                                      );
                                      setFieldValue(
                                        "street",
                                        res.data.results.street
                                      );
                                      setFieldValue(
                                        "post_code",
                                        res.data.results.post_code
                                      );
                                      setFieldValue(
                                        "city",
                                        res.data.results.city
                                      );
                                    })
                                    .catch((error) => {
                                      enqueueSnackbar(
                                        "[Dane NIP] Błąd pobierania danych: " +
                                          error,
                                        {
                                          variant: "error",
                                        }
                                      );
                                    });
                                }}
                              >
                                <Typography fontWeight={400}>
                                  pobierz dane na podstawie NIP
                                </Typography>
                              </ColorButton>
                            </center>
                          </Grid>
                        </>
                      )}
                      {values.company !== "" && (
                        <>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="street"
                              id="street"
                              variant="filled"
                              error={touched.street && Boolean(errors.street)}
                              helperText={
                                touched.street && errors.street
                                  ? errors.street
                                  : null
                              }
                              label="Ulica i numer"
                              value={values.street}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            style={{ marginTop: 15 }}
                            sx={{
                              paddingRight: { xs: 0, sm: 5 },
                            }}
                          >
                            <TextField
                              fullWidth
                              name="post_code"
                              id="post_code"
                              variant="filled"
                              error={
                                touched.post_code && Boolean(errors.post_code)
                              }
                              helperText={
                                touched.post_code && errors.post_code
                                  ? errors.post_code
                                  : null
                              }
                              label="Kod pocztowy"
                              value={values.post_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={8} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="city"
                              id="city"
                              variant="filled"
                              error={touched.city && Boolean(errors.city)}
                              helperText={
                                touched.city && errors.city ? errors.city : null
                              }
                              label="Miasto"
                              value={values.city}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <FormControl
                              component="fieldset"
                              error={
                                touched.recipient && Boolean(errors.recipient)
                              }
                            >
                              <FormLabel component="legend">
                                Odbiorca na fakturze
                              </FormLabel>
                              <FormControlLabel
                                fullWidth
                                name="recipient"
                                control={<Checkbox />}
                                id="recipient"
                                variant="filled"
                                label="Dodaj dane odbiorcy"
                                checked={values.recipient}
                                onClick={handleChange}
                                onBlur={handleBlur}
                              />
                              <FormHelperText>
                                {touched.recipient && errors.recipient
                                  ? errors.recipient
                                  : null}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          {values.recipient === true && (
                            <>
                              <Grid xs={12}>
                                <h2>Odbiorca na fakturze</h2>
                              </Grid>
                              <Grid item xs={12} style={{ marginTop: 15 }}>
                                <TextField
                                  fullWidth
                                  name="recipient_name"
                                  id="recipient_name"
                                  variant="filled"
                                  error={
                                    touched.recipient_name &&
                                    Boolean(errors.recipient_name)
                                  }
                                  helperText={
                                    touched.recipient_name &&
                                    errors.recipient_name
                                      ? errors.recipient_name
                                      : null
                                  }
                                  label="Nazwa odbiorcy"
                                  value={values.recipient_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={12} style={{ marginTop: 15 }}>
                                <TextField
                                  fullWidth
                                  name="recipient_street"
                                  id="recipient_street"
                                  variant="filled"
                                  error={
                                    touched.recipient_street &&
                                    Boolean(errors.recipient_street)
                                  }
                                  helperText={
                                    touched.recipient_street &&
                                    errors.recipient_street
                                      ? errors.recipient_street
                                      : null
                                  }
                                  label="Ulica i numer odbiorcy"
                                  value={values.recipient_street}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                style={{ marginTop: 15 }}
                                sx={{
                                  paddingRight: { xs: 0, sm: 5 },
                                }}
                              >
                                <TextField
                                  fullWidth
                                  name="recipient_post_code"
                                  id="recipient_post_code"
                                  variant="filled"
                                  error={
                                    touched.recipient_post_code &&
                                    Boolean(errors.recipient_post_code)
                                  }
                                  helperText={
                                    touched.recipient_post_code &&
                                    errors.recipient_post_code
                                      ? errors.recipient_post_code
                                      : null
                                  }
                                  label="Kod pocztowy odbiorcy"
                                  value={values.recipient_post_code}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={8}
                                style={{ marginTop: 15 }}
                              >
                                <TextField
                                  fullWidth
                                  name="recipient_city"
                                  id="recipient_city"
                                  variant="filled"
                                  error={
                                    touched.recipient_city &&
                                    Boolean(errors.recipient_city)
                                  }
                                  helperText={
                                    touched.recipient_city &&
                                    errors.recipient_city
                                      ? errors.recipient_city
                                      : null
                                  }
                                  label="Miasto odbiorcy"
                                  value={values.recipient_city}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                      )}

                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <h2>Szczegóły</h2>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="quantity"
                          id="quantity"
                          variant="filled"
                          error={touched.quantity && Boolean(errors.quantity)}
                          helperText={
                            touched.quantity && errors.quantity
                              ? errors.quantity
                              : "Podaj liczbę osób, za które chcesz opłacić DSCZ. Jeżeli płacisz tylko za siebie lub jedną osobę, to wpisz 1."
                          }
                          label="Liczba osób"
                          value={values.quantity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="number"
                        />
                      </Grid>

                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          name="description"
                          id="description"
                          variant="filled"
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          helperText={
                            touched.description && errors.description
                              ? errors.description
                              : "Informacja z tego pola zostanie umieszczona na fakturze."
                          }
                          label="Dane osób (imiona i nazwiska) lub imię i nazwisko patrolowego w przypadku grupowych zgłoszeń"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <FormControl
                          name="fieldset"
                          variant="standard"
                          error={touched.member && Boolean(errors.member)}
                        >
                          <FormLabel component="legend">
                            Oświadczam, co nastepuje:
                          </FormLabel>
                          <FormControlLabel
                            fullWidth
                            name="member"
                            control={<Checkbox />}
                            id="member"
                            variant="filled"
                            helperText={
                              touched.member && errors.member
                                ? errors.member
                                : null
                            }
                            label={
                              <>
                                <Typography>
                                  1. Wpłata dokonywana jest za osobę (uczestnika
                                  zadania), która jest członkiem Związku
                                  Harcerstwa Polskiego;
                                </Typography>
                                <Typography>
                                  2. Przyjmuję do wiadomości i akceptuję, że w
                                  przypadku, w którym oświadczenie z pkt 1
                                  powyżej okazałoby się nieprawdziwe, będzie to
                                  wiązać się z koniecznością poniesienia przez
                                  Związek Harcerstwa Polskiego dodatkowych
                                  kosztów, obejmujących m.in. odprowadzenie
                                  należnego podatku od towarów i usług (VAT);
                                </Typography>
                                <Typography>
                                  3. W sytuacji, o której mowa w pkt 2 powyżej,
                                  zobowiązuję się do pokrycia tych dodatkowych
                                  kosztów na każde żądanie ze strony Związku
                                  Harcerstwa Polskiego.
                                </Typography>
                              </>
                            }
                            checked={values.member}
                            onClick={handleChange}
                            onBlur={handleBlur}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => window.open("/gdpr", "_blank")}
                          >
                            Obowiązek informacyjny w związku z przetwarzaniem
                            danych osobowych dla osób spoza ZHP.
                          </Link>
                        </Typography>
                      </Grid>
                      {values.event !== "" && (
                        <>
                          <Grid item xs={12} sm={6} style={{ marginTop: 15 }}>
                            <strong>Do zapłaty:</strong>
                            <Typography>
                              {(
                                products.find((p) => p.id === values.event)
                                  .price * values.quantity
                              ).toFixed(2)}{" "}
                              zł.
                            </Typography>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY}
                          onChange={(event) => setFieldValue("captcha", event)}
                        />
                        {errors.captcha && touched.captcha && (
                          <Typography
                            style={{
                              color: "#d32f2f",
                              fontSize: "0.75rem",
                              paddingLeft: "0.75rem",
                              paddingTop: "0.25rem",
                            }}
                          >
                            {errors.captcha}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          alignSelf="left"
                          spacing={1}
                          useFlexGap
                          sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
                        >
                          <ColorButton
                            variant="contained"
                            type="submit"
                            disabled={loading}
                          >
                            PRZEŚLIJ
                          </ColorButton>
                          <ColorButton
                            onClick={() => handleReset()}
                            variant="contained"
                          >
                            ANULUJ
                          </ColorButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </>
            )}
          </Formik>
        </Stack>
      </Container>
    </Box>
  );
}
