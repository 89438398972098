import React, { useEffect, useState } from "react";
import Form from "../components/Form";

export default function FormPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Form />
    </>
  );
}
