import axios from "axios";

export async function addInvoice(jsondata) {
  const data = await axios({
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/AddInvoice",
    "Content-Type": "application/json",
    data: jsondata,
  });

  return data;
}
